import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const RecentScholarlyImpactPage = () => (
  <Layout>
    <Seo title="Public Speaking and Testimony" />
    <div id="content">
      <div id="feature_box"></div>
      <div class="post_box top" id="post-33">
        <div class="format_text">
          <h1>Public Speaking and Testimony</h1>
          <p>
            <span
              style={{
                float: "left",
                margin: "0 0.5em 0.5em 0.5em",
                textAlign: "left",
                display: "block",
              }}
            >
              <StaticImage
                src="../images/shutterstock_11385169-Public-Speaking-Testimony-Presentation-300x196.jpeg"
                alt="Pen on legal document"
              />
            </span>
            He was invited and provided expert testimony (economic analysis and
            policy strategy regarding capital markets as an investment banker)
            before hearings of the United States Congress.
          </p>
          <p>Other speaking engagements include:</p>
          <br />
          <ul>
            <li>
              2022 Academy of Legal Studies in Business (ALSB) Annual
              Conference, Louisville, Kentucky, July 26-August 1, 2022.{" "}
              <i>
                When Does A Nonfungible Token (NFT) Become A Security? (with
                Brian Elzweig). Climate Change and Caremark (with H. Justin
                Pace).
              </i>
            </li>
            <li>
              Ninth Annual Governance of Emerging Technologies and Science
              Conference, Arizona State University, Sandra Day O’Connor College
              of Law, Center for Law, Science & Innovation, Phoenix, Arizona,
              May 19-20, 2022.{" "}
              <i>
                Tik Toc! Tik Toc: Escalating Tension Between U.S. Privacy Rights
                and National Security Vulnerabilities.
              </i>
            </li>
            <li>
              Southern Academy of Legal Studies in Business, San Antonio, Texas,
              March 3-5, 2022.{" "}
              <i>
                When Does A Nonfungible Token (NFT) Become A Security? (with
                Brian Elzweig).
              </i>
            </li>
            <li>
              Academy of Legal Studies in Business (ALSB) Technology Colloquium,
              January 28, 2022.{" "}
              <i>
                Mission Critical: Caremark. Blue Bell, and Director
                Responsibility for Cybersecurity Governance (presented with my
                co-author H. Justin Pace) (Winner of the 2021 SEALSB Best
                Proceedings Paper Award), available at
              </i>{" "}
              <a href="http://ssrn.com/abstract=3938128">
                http://ssrn.com/abstract=3938128
              </a>
              .
            </li>
            <li>
              Southeast Academy of Legal Studies in Business (SEALSB) Annual
              Conference, November 11-12, 2021.{" "}
              <i>
                Mission Critical: Caremark. Blue Bell, and Director
                Responsibility for Cybersecurity Governance (presented by my
                co-author H. Justin Pace) (Winner of the 2021 SEALSB Best
                Proceedings Paper Award), available at
              </i>{" "}
              <a href="http://ssrn.com/abstract=3938128">
                http://ssrn.com/abstract=3938128
              </a>
              .
            </li>
            <li>
              2021 Academy of Legal Studies in Business (ALSB) Virtual Annual
              Conference, August 1-6, 2021.{" "}
              <i>
                Presiding, ALSB Technology Section (Annual Meeting). Presenting
                “Virtual Art and Non-Fungible Tokens” (with Joshua A.T.
                Fairfield). Presenting “Securities Law: Overview and
                Contemporary Issues (with Neal Newman). Presenting “Corporate
                Governance and Cybersecurity Risk (with Frederick R. Chang- Fmr.
                Director of Research, NSA; and Ronald D. McCray- Seasoned
                Corporate Director).
              </i>
            </li>
            <li>
              Pacific Southwest Academy of Legal Studies in Business, 27th
              Annual (Virtual) Conference, February-12-13, 2021.{" "}
              <i>Presenting “Contracts in Brief.”</i>
            </li>
            <li>
              Southeast Academy of Legal Studies in Business (SEALSB) Virtual
              66th Annual Conference, November 13, 2020.{" "}
              <i>
                Presenting “The Demographics of Death: An Early Look at
                Covid-19, Cultural and Racial Bias in America.”
              </i>
            </li>
            <li>
              Business Law Association, Prairie View A&M University, November 9,
              2020.{" "}
              <i>
                Corporate Directors: Who They Are, What They Do, Cyber and Other
                Contemporary Challenges (with Seletha Butler, Frederick R.
                Chang, Michele Hooper, Ron McCray and Ruth Simmons).
              </i>
            </li>
            <li>
              Oklahoma City University School of Law, October 21, 2020.{" "}
              <i>
                “Blockchain, Virtual Currencies & the Struggle of Law to Keep
                Pace.”
              </i>
            </li>
            <li>
              2020 Academy of Legal Studies in Business (ALSB) Virtual Annual
              Conference, August 3-7, 2020.{" "}
              <i>
                Presenting “Rapid Technological Change and U.S. Entrepreneurial
                Risk in International Markets: Focus on Data Security,
                Information Privacy, Bribery and Corruption; and “Posted: No
                Phishing.”
              </i>
            </li>
            <li>
              2020 Symposium on Legal, Ethical, and Compliance Issues for
              Emerging Markets: Cannabis in the States, cohosted by the Center
              for Legal Studies in Business Ethics at Oklahoma State University
              and the American Business Law Journal, February 27-29, 2020 in
              Oklahoma City.{" "}
              <i>
                As lead author, I presented “Cannabis At The Crossroads:
                Regulation, Compliance, and Ethical Considerations in the United
                States,” (with Donald Mayer, Paul Seaborn, Adam Sulkowski and
                Robert T. Luttrell).
              </i>
            </li>
            <li>
              2019 Academy of Legal Studies in Business (ALSB) Annual
              Conference, Montreal, Quebec, August 6-10, 2019.{" "}
              <i>
                Keynote Speaker, Law and Accountants Section Breakfast. Teaching
                Ethics and Values in an Age of Rapid Technological Change (with
                Kenneth J. Sanney, Eric D. Yordy, Tammy W. Cowart, and Destynie
                Sewell) (accepted and Trautman to present at JLSE Invited
                Scholars Colloquium.
              </i>
            </li>
            <li>
              Seventh Annual Conference on Governance of Emerging Technologies,
              Arizona State University, Sandra Day O’Connor College of Law,
              Center for Law, Science & Innovation, Phoenix, Arizona, May 22-23,
              2019. <i>Governance of The Internet of Things (IoT).</i>
            </li>
            <li>
              Cal Poly Pomona, College of Business Administration, Pomona
              California, February 14, 2019.{" "}
              <i>
                Corporate Data Breaches: Corporate Governance of Cybersecurity
                Risk
              </i>
            </li>
            <li>
              Southeastern Academy of Legal Studies in Business, 64th Annual
              Conference, Chattanooga, Tennessee, Oct. 25-27, 2018.{" "}
              <i>
                The Dark Web and Employer Liability (w/David Schein). The
                Twenty-Fifth Amendment: Incapacity and Ability to Discharge the
                Powers and Duties of Office?
              </i>
            </li>
            <li>
              Blockchain Technology &amp; Organizations Research Symposium,
              University of Connecticut, Stamford, CT, August 15-16, 2018.{" "}
              <i>
                Bitcoin, Virtual Currencies, and the Struggle of Law and
                Regulation to Keep Pace.
              </i>
            </li>
            <li>
              2018 Academy of Legal Studies in Business (ALSB) Annual
              Conference, Portland, OR, August 10-13, 2018.{" "}
              <i>
                Bitcoin, Virtual Currencies, and the Struggle of Law and
                Regulation to Keep Pace; WannaCry, Ransomware, &amp; the
                Emerging Threat to Corporations, (w/ Peter Ormerod).
              </i>
            </li>
            <li>
              Southern Academy of Legal Studies in Business, San Antonio, Texas,
              March 23, 2018;{" "}
              <i>
                Bribery and Corruption: The COSO Framework, FCPA, and The U.K.
                Bribery Act.
              </i>
            </li>
            <li>
              Southeastern Academy of Legal Studies in Business, 63rd Annual
              Conference, Atlanta, Georgia, Nov. 9-11, 2017.{" "}
              <i>
                How Google Perceives Customer Privacy, Cyber, E-Commerce,
                Political and Regulatory Compliance Risks.
              </i>
            </li>
            <li>
              Oklahoma City University School of Law, Oklahoma City, Oklahoma,
              October 19, 2017.{" "}
              <i>
                “Cybersecurity and Corporate Governance,” discussion presented
                to cybersecurity law Course.
              </i>
            </li>
            <li>
              2017 “How Law Operates in a Wired Society,” Legal Scholars
              Roundtable Sponsored by Korea Legislation Research Institute
              (Korean Government-funded think tank), Seoul, Korea, September
              21-22, 2017.
            </li>
            <li>
              2017 Academy of Legal Studies in Business (ALSB) Annual
              Conference, Savannah, GA, August 6-10, 2017.{" "}
              <i>
                “Cyber Threats to Business: Identifying and Responding to
                Digital Attacks,” (Chair); “Industrial Cyber Vulnerabilities:
                Lessons from Stuxnet and the Internet of Things (IoT)”; “Digital
                is Different: Carpenter v. United States, The Fourth Amendment,
                The Stored Communications Act, and Cell Site Locational
                Information”; Moderator, Development Track Session: Curriculum /
                Pedagogy
              </i>
            </li>
            <li>
              Southern Academy of Legal Studies in Business, 2017 Annual
              Meeting, San Antonio, Texas, March 23-25, 2017.{" "}
              <i>
                “Governing Risk and The Information Silo Problem: Engineering A
                Systemic Cultural and Communications Solution for Cyber.”
              </i>
            </li>
            <li>
              Anti-Corruption Law Interest Group (ASIL) (Wharton &amp; U Miami)
              Conference, University of Miami, Miami, FL, January 13-14, 2017.{" "}
              <i>
                “Following the Money: Lessons from the “Panama Papers;” Part 1:
                Tip of the Iceberg.”
              </i>
            </li>
            <li>
              Southeastern Academy of Legal Studies in Business, 62nd Annual
              Meeting, Durham, NC, November 10-12, 2016.{" "}
              <i>
                “International and Comparative Business and Financial
                Regulation,” (moderator of academic session); “Personal Ethics
                and the Financial Collapse of 2007-08.”<i></i>
              </i>
            </li>
            <i>
              <i>
                <li>
                  2016 Academy of Legal Studies in Business (ALSB) Annual
                  Conference, San Juan, PR, August 7-12, 2016.{" "}
                  <i>
                    “Following the Money: Lessons from the “Panama Papers;” Part
                    1: Tip of the Iceberg.”; “Governing Risk and the Information
                    Silo Problem: Engineering a Systemic Cultural and
                    Communications Solution for Cyber” (Developmental Paper);
                    “Entrepreneurship Law” panel discussion with: Josephine
                    Nelson, Stanford University (Chair); Connie Bagley, Yale
                    University; and Bonnie Persons, Chico State.
                  </i>
                </li>
                <li>
                  University of Florida, January 29, 2016 (Business School){" "}
                  <i>
                    “The GM Switch Crisis and Lessons in Governance” (one
                    section, graduate ethics symposium).
                  </i>
                </li>
                <li>
                  UC Davis School of Law, Davis, CA, November 6, 2015.{" "}
                  <i>
                    “Corporate Data Breaches: What Companies Can Learn From
                    Recent High Profile Attacks,” Annual Symposium of the UC
                    Davis Business Law Journal.
                  </i>
                </li>
                <li>
                  2015 Academy of Legal Studies in Business (ALSB) Annual
                  Conference, Philadelphia, PA, August 6-10, 2015.{" "}
                  <i>
                    “Some Key Things Entrepreneurs Need to Know About the Law
                    and Lawyers.”
                  </i>
                </li>
                <li>
                  2015 Academy of Legal Studies in Business (ALSB) Annual
                  Conference, Philadelphia, PA, August 6-10, 2015.{" "}
                  <i>
                    “The Value of Legal Writing, Law Review, and Publication.”
                  </i>
                </li>
                <li>
                  2015 Academy of Legal Studies in Business (ALSB) Annual
                  Conference, Philadelphia, PA, August 6-10, 2015.{" "}
                  <i>
                    “E-Commerce, Cyber, and Electronic Payment System Risk:
                    Lessons from PayPal.”
                  </i>
                </li>
                <li>
                  USASBE and 3rd Annual Midwest Symposium on Social
                  Entrepreneurship, Kansas City, MO, May 18-19, 2015.{" "}
                  <i>“Crowdfunding for Social Entrepreneurship”.</i>
                </li>
                <li>
                  Texas Tech University, March 31, 2015 (College of Media and
                  Communication).{" "}
                  <i>“Enhancing Team Meetings and Corporate Governance”.</i>
                </li>
                <li>
                  George Washington University, March 24, 2015 (School of
                  Engineering and Applied Sciences, Engineering Management and
                  System Engineering Department).{" "}
                  <i>
                    “If An Electronic Pearl Harbor Were Possible, What Would It
                    Look Like?” EMSE 6537; Julie J.C.H. Ryan’s Information
                    Operation’s class.
                  </i>
                </li>
                <li>
                  University of Florida, March 16, 2015 (Business School).{" "}
                  <i>
                    “Fiduciary Duties of Corporate Directors” (one section,
                    undergraduate bus. law class). “Cyber Ethics” (one
                    undergraduate business ethics class).
                  </i>
                </li>
                <li>
                  Jacksonville University, Jacksonville, Florida, March 13,
                  2015.{" "}
                  <i>
                    “Entrepreneurship: Financing the Early-Stage Enterprise”
                    (Emphasis on Crowdfunding).
                  </i>
                </li>
                <li>
                  Annual meeting of the United States Association of Small
                  Business and Entrepreneurship (USASBE), Tampa, Florida,
                  January 25, 2015. <i>“The Roar of the Crowdfunding.”</i>
                </li>
                <li>
                  Annual conference of the Academy of Legal Studies in Business
                  (ALSB), Seattle, Washington, August 5, 2014.{" "}
                  <i>
                    “Entrepreneurial Risk in International Markets: Focus on
                    Bribery and Corruption”.
                  </i>
                </li>
                <li>
                  Annual conference of the Academy of Legal Studies in Business
                  (ALSB), Seattle, Washington, August 6, 2014.{" "}
                  <i>“Boardroom Diversity: Why It Matters.”</i>
                </li>
                <li>
                  Paper presented at the Academy of Management (AOM) Content
                  Analysis Professional Development Workshop, Philadelphia,
                  Friday, August 1, 2014.{" "}
                  <i>
                    Identifying E-Commerce Strategic Risks: Mining the SEC
                    Filings of eBay and PayPal, (with David Altounian).
                  </i>
                </li>
                <li>
                  George Washington University, November 16, 2013.{" "}
                  <i>
                    “Board Nominating Essentials,” segment of GWU ON THE BOARD
                    program.
                  </i>
                </li>
                <li>
                  University of Florida, November 13, 2013.{" "}
                  <i>The Importance of Truth-Telling in Corporate Culture.</i>
                </li>
                <li>
                  University of Florida, November 12, 2013.{" "}
                  <i>
                    The Role of Ethics in Management, GEB 6930 (Guest Speaker).
                  </i>
                </li>
                <li>
                  George Washington University School of Law, October 21, 2013.{" "}
                  <i>
                    Denit Trust Challenges in Corporate Governance Series: “The
                    Role of Corporate; Directors In a Crisis,” with Alan Beller
                    (Cleary Gottlieb), John Olson (Gibson, Dunn &amp; Crutcher),
                    and Former SEC Commissioners Cynthia Glassman and Laura
                    Unger.
                  </i>
                </li>
                <li>
                  ISIS-San Antonio International Multidisciplinary Academic
                  Conference, Oct. 18-20, 2013.{" "}
                  <i>
                    Corporate Information Governance Under Fire (w/ Jason Triche
                    &amp; James C. Wetherbe).
                  </i>
                </li>
                <li>
                  Harvard University- Summer 2012.{" "}
                  <i>
                    Corporate Governance MGMT S-5018 (Guest Speaker); “Board of
                    Directors: Selection, Compensation, and Removal,” July 5,
                    2012.{" "}
                  </i>
                </li>
              </i>
            </i>
          </ul>
          <i>
            <i>
              <p>
                Presided at in excess of one hundred meetings of various
                chapters of the National Association of Corporate Directors
                and/or the Dallas Internet Society, serving as Chairman and/or
                President. A representative list of presentations includes:
              </p>
              <ul>
                <li>
                  Shareholder Activism, (Program Chair), Dallas Chapter,
                  National Association of Corporate Directors, May 14, 2013.
                </li>
                <li>
                  Healthcare 911: Oversight from the Boardroom, (Program Chair),
                  Dallas Chapter, National Association of Corporate Directors,
                  April 10, 2013.
                </li>
                <li>
                  Audit Committee Developments &amp; Overview, (Program Chair),
                  Dallas Chapter, National Association of Corporate Directors,
                  March 5, 2013.
                </li>
                <li>
                  Director’s Responsibility for Information Technology
                  Governance, (Program Chair and Moderator), Dallas Chapter,
                  National Association of Corporate Directors, Feb. 12, 2013.
                </li>
                <li>
                  Director Responsibility for Risk Management, (Program chair),
                  Dallas Chapter, National Association of Corporate Directors,
                  Jan. 8, 2013.
                </li>
                <li>
                  SEC Enforcement: What to Do When the SEC Calls, (Program
                  chair), Dallas Chapter, National Association of Corporate
                  Directors, Nov. 13, 2012.
                </li>
                <li>
                  The Effective Lead Director, (Program chair), Dallas Chapter,
                  National Association of Corporate Directors, Oct. 2, 2012.
                </li>
                <li>
                  Boardroom Demand: What Boards Desire in New Directors,
                  (Program chair), Dallas Chapter, National Association of
                  Corporate Directors, Sept. 5, 2012.
                </li>
                <li>
                  Harvard University- Summer 2012, Corporate Governance MGMT
                  S-5018 (Guest Speaker); Board of Directors: Selection,
                  Compensation, and Removal. July 5, 2012.
                </li>
                <li>
                  Bribery &amp; Extortion: What You Need to Know, with Alexandra
                  Wrage, President, Trace International (Moderator and Program
                  chair), Dallas Chapter, National Association of Corporate
                  Directors, June 12, 2012.
                </li>
                <li>
                  Role of the Director in M&amp;A Transactions, (Program chair),
                  Dallas Chapter, National Association of Corporate Directors,
                  May 8, 2012.
                </li>
                <li>
                  When the Board Needs an Investigation, (Program chair), Dallas
                  Chapter, National Association of Corporate Directors, April
                  10, 2012.
                </li>
                <li>
                  What Directors Need to Know About the Audit Committee,
                  “Financial Expert,” &amp; Information Technology Risk, with
                  Dennis Beresford (Program chair), Dallas Chapter, National
                  Association of Corporate Directors, Feb. 14, 2012.
                </li>
                <li>
                  The Role of the Board of Directors in the Development &amp;
                  Execution of Enterprise Wide Strategy, (Program chair), Dallas
                  Chapter, National Association of Corporate Directors, Jan. 10,
                  2012.
                </li>
                <li>
                  Why Is Strategic Leadership Important to the American Economy?
                  Presentation before the School of Business, Ohio University,
                  Athens, Ohio, December 6, 2011.
                </li>
                <li>
                  The Foreign Corrupt Practices Act: Minefield for Directors,
                  (Program chair), Dallas Chapter, National Association of
                  Corporate Directors, Nov. 1, 2011.
                </li>
                <li>
                  Role of the Lead Director, (Program chair), Dallas Chapter,
                  National Association of Corporate Directors, Oct. 11, 2011.
                </li>
                <li>
                  Executive Compensation Disclosures: A Look Back to 2011 and a
                  Look Forward, (Program chair) Dallas Chapter, National
                  Association of Corporate Directors, Sept. 13, 2011.
                </li>
                <li>
                  The Board in Crisis: What To Do When The Unthinkable Happens
                  (Program chair &amp; moderator) with Admiral Bobby R. Inman
                  USN (Retired), Dallas Chapter, National Association of
                  Corporate Directors, Oct. 13, 2010.
                </li>
                <li>
                  Oil &amp; Gas 2006 (conference moderator), Cox Convention
                  Center, Oklahoma City, OK (April 27, 28 and 29, 2006).
                </li>
                <li>
                  Corporate Governance Issues In An Oil &amp; Gas Setting (panel
                  moderator) presented at OIL &amp; GAS 2006, Cox Convention
                  Center, Oklahoma City, OK (April 27, 28 and 29, 2006).
                </li>
                <li>
                  The Role Of Attorney In A Large Energy Company (moderator)
                  with Tom McDaniel, President, Oklahoma City University and
                  former Vice Chairman of Kerr-McGee Corporation, before the
                  Energy Law Association, Oklahoma City University School of
                  Law, September 7, 2005.
                </li>
                <li>
                  Land and Legal Relations From the Perspective Of Major Natural
                  Gas Operators (moderator) with Henry J. Hood, Senior Vice
                  President of Land and Legal, Chesapeake Energy Corporation,
                  before the Energy Law Association, Oklahoma City University
                  School of Law, October 19, 2005.
                </li>
                <li>
                  CEOs Speak: Reports from the Front Line (Moderator) Dallas
                  Internet Society, March 15, 2001, Infomart, Dallas, Texas.
                </li>
                <li>
                  Internet Advertising: Update &amp; Future Trends (Moderator)
                  Dallas Internet Society, Feb. 15, 2001, Infomart, Dallas,
                  Texas.
                </li>
                <li>
                  What You Need to Know About Data Security (Moderator) Dallas
                  Internet Society, Jan. 18, 2001, Infomart, Dallas, Texas.
                </li>
                <li>
                  Internet Job Market: Dynamics &amp; Update: (Moderator) Dallas
                  Internet Society, Oct. 19, 2000, Infomart, Dallas, Texas.
                </li>
                <li>
                  Austin: Internet Industry Update (Moderator) Dallas Internet
                  Society, Aug. 24, 2000, Infomart, Dallas, Texas.
                </li>
                <li>
                  The Internet: Report From Houston (Moderator) Dallas Internet
                  Society, July 20, 2000, Infomart, Dallas, Texas.
                </li>
                <li>
                  Venture Capital: Where Do We Go From Here? (Moderator) Dallas
                  Internet Society, June 22, 2000, Infomart, Dallas, Texas.
                </li>
                <li>
                  Driving Traffic To Website Via Gaming / Viral Marketing
                  (Moderator) Dallas Internet Society, April 13, 2000, Infomart,
                  Dallas, Texas.
                </li>
                <li>
                  Presided over 50-something monthly meetings of the New York
                  and Metropolitan Washington, DC / Baltimore chapters of the
                  National Association of Corporate Directors.
                </li>
              </ul>
            </i>
          </i>
        </div>
        <i>
          <i></i>
        </i>
      </div>
      <i>
        <i></i>
      </i>
    </div>
  </Layout>
)

export default RecentScholarlyImpactPage
